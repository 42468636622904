import React, { useCallback, useContext, useRef, useState } from 'react';
import Router from 'next/router';
import InlineLoader from '../InlineLoader';
import Price from '../Price';
import LoadImage from '../LoadImage';
import notificationTypes from '../../constants/modalNotificationConst';
import getTransformedImageURLs from '../../helpers/getTransformedImageURLs';
import { useModifyCart } from '../../hooks/cart';
import appContext from '../../Context/appContext';

import { SCHEDULE_TYPE_ASAP } from '../../constants/scheduleType';
import { ASAP, Scheduled } from '../../constants/scheduleWindow';
import DiscountInPercent from '../DiscountInPercent';
import * as WEBSITES from '../../constants/website';
import { SCHEDULE } from '../../constants/deliveryType';
import * as ScheduleType from '../../constants/scheduleWindow';
import getHighestValueAttribute from '../../helpers/getHighestValueAttribute';
import { getProductAttributeValue } from '../../helpers/productAttributeHelper';
import ProductCounter from '../ProductCounter';
import AddToCartButton from '../AddToCartButton';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification, popNotification, increaseURLstack } from '../../redux/slices/modalSlice';
import { setUrlBeforeProduct } from '../../redux/slices/userSlice';
import { setPDPOpenedFromWidget } from '../../redux/slices/cartSlice';

const ProductTileInCart = React.memo(({ element, categoryType, cartItems, categoryIndex, productIndex }) => {
  // const {
  //   dispatch,
  //   // state: {
  //   //   user: {
  //   //     deliveryDetails: { deliveryTypeSelected }
  //   //   },
  //   //   cart: { showCartWidget }
  //   // }
  // } = useContext(appContext);
  const { showCartWidget } = useSelector(state => state.cart) || {}
  const { deliveryDetails: { deliveryTypeSelected } } = useSelector(state => state.user)
  const dispatchRedux = useDispatch()

  const [productCounterType, setProductCounterType] = useState('count');
  const [showLoader, setShowLoader] = useState(false)


  const autoDismissWidgetTimeout = useRef(null);

  function openProductDetails(data) {
    let tab;
    if (data?.menu) {
      tab = data?.menu === ScheduleType.Scheduled ? SCHEDULE : ASAP;
    } else {
      tab = data?.list === 'Schedule List' ? SCHEDULE : ASAP;
    }
    if (
      [
        WEBSITES.KOAN,
        WEBSITES.DRINKCANN,
        WEBSITES.WUNDER,
        WEBSITES.CANNADIPSTHC,
        WEBSITES.BEED,
        WEBSITES.POTLI,
        WEBSITES.ALTLIFE,
        WEBSITES.HELLOAGAIN,
        WEBSITES.VETCBD,
        // WEBSITES.HUMBOLDTFAMILYFARMS,
        WEBSITES.MAISONBLOOM,
        WEBSITES.BOASTCANNABIS,
        WEBSITES.RIGHTRATIO,
        WEBSITES.GETOOKA
      ].includes(process.env.NEXT_PUBLIC_APP)
    ) {
      if (data.bundle_id) {
        Router.push('/bundle/[slug]', `/bundle/${data.slug}?tab=${tab}`);
      } else {
        Router.push('/product/[slug]', `/product/${data.slug}?tab=${tab}`);
      }
    } else {
      let url = '';
      let isBundle = true;
      if (data.bundle_id) {
        url = `/bundle/${data.slug}?tab=${tab}`;
      } else {
        isBundle = false;
        url = `/product/${data.slug}?tab=${tab}`;
      }
      if (showCartWidget) {
        // dispatch({ type: 'setPDPOpenedFromWidget', payload: true });
        dispatchRedux(setPDPOpenedFromWidget(true))
      }
      dispatchRedux(popNotification())
      dispatchRedux(pushNotification({ type: notificationTypes.productDetails, data: { slug: data.slug, tab, page: '', isBundle, widgetCategoryProduct: categoryType, widgetType: 'View-cart Upsell' } }))
      dispatchRedux(increaseURLstack())
      dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname + window.location.search}` }))
      window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
    }
  }
  const startAutoDismissWidget = useCallback(
    () => {
      clearInterval(autoDismissWidgetTimeout.current);
      autoDismissWidgetTimeout.current = setTimeout(() => {
        setProductCounterType('count');
      }, 4000);
    },
    []
  )
  const handleProductClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (productCounterType == 'count') {
      setProductCounterType('counter');
      startAutoDismissWidget();
    }

  }, [setProductCounterType])


  const dismissWidget = useCallback(() => {
    setProductCounterType('count');

  }, [])

  const isSomeDealAvailable = element.is_deal_available || element.is_pre_deal_available;
  const bulkBasePrice = element && element.bulk_base_price ? element.bulk_base_price : false;
  const orignalPrice = isSomeDealAvailable ? element.price_without_deal : bulkBasePrice;

  const { price: productFinalPrice, price_without_deal: priceWithoutDeal, deal_price_type: dealPriceType } = element;

  const productImage = getTransformedImageURLs(element.product_image).srcSmall;
  const weight =
    (element.category_unit || element.product_unit) === '1/8 oz' || (element.category_unit || element.product_unit) === '1/2 oz'
      ? `${element.category_unit || element.product_unit || ''}`
      : `${element.category_weight || element.product_weight || ''}${element.category_unit || element.product_unit || ''}`;

  const productAltText = element.image_alt_tag && element.image_alt_tag !== '' ? element.image_alt_tag : element.product_name;
  return (
    <div key={element.bundle_id || element.product_details_id} className="latest-product product-in-tile relative r-adjust-padding cart-product-tile">
      <div className="product-space-between-container">
        {isSomeDealAvailable && (
          <div className="offer-holder position-absolute">
            <div className="sale">
              <DiscountInPercent
                productFinalPrice={productFinalPrice}
                priceWithoutDeal={priceWithoutDeal}
                dealPriceType={dealPriceType}
              />
            </div>
          </div>
        )}
        <div className="top-container">
          <div onClick={() => openProductDetails(element)} className="link-wrapper img-wrapper">
            <LoadImage className=" img-fluid cursor-pointer " src={productImage} alt={productAltText} height={200} width={200} />
          </div>
          <div className="strain-fix-container d-flex align-items-center justify-content-between">
            <>
              {element.bundle_id ? null : (
                <>
                  {element.product_strain_type_name && (
                    <div className={`strain-container text-uppercase ${element.product_strain_type_name}  d-inline-block `}>
                      {element.product_strain_type_name}
                    </div>
                  )}
                </>
              )}
            </>
            <span className="weight-container font-weight-normal  m-0">{weight.replace(/(\d+)\s?grams?/g, "$1 g")}</span>
          </div>
        </div>
        <div className="bottom-container pt-2 cursor-pointer" onClick={() => openProductDetails(element)}>
          <div className="prod-details-wrap">
            <p className="bold mb-2 d-inline-block text-clamp mt-0">{element.product_name}</p>
            {element.product_attributes && element.product_attributes.length ? (
              <>
                {getHighestValueAttribute(element.product_attributes, weight, element.product_unit)
                  .filter(item => item)
                  .map(item => {
                    return (
                      <h6 className="thc-cbd mb-1  md-grey-color">{item.name} - {getProductAttributeValue(item)}</h6>
                    );
                  })}
              </>
            ) : null}
            <div className="brand-name md-grey-color">{element?.brand_names?.[0]}</div>
          </div>
          <div className="price-offer-cart-wrapper d-flex align-items-center justify-content-between">
            <div className=" price-container">
              <Price productDetails={element} orignalPrice={orignalPrice} priceClassName="d-inline" />
            </div>
            <div className="new-add-to-cart counter-wrap">
              <AddToCartButton
                isSoldOut={element.is_sold_out}
                handleProductClick={handleProductClick}
                // itemInCart={itemInCart}
                productCounterType={productCounterType}
                productIndex={productIndex}
                categoryIndex={categoryIndex}
                data={element}
                setShowLoader={setShowLoader}
              />
              <ProductCounter
                page={`${categoryType} list`}
                from={'View-cart Upsell'}
                className="version-2"
                actionMade={startAutoDismissWidget}
                dismiss={dismissWidget}
                type={productCounterType}
                product={element}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                // itemInCart={itemInCart}
                // quantity={quantity}
                // onDeleteItem={dismissWidget}
                scheduleType={deliveryTypeSelected == SCHEDULE_TYPE_ASAP ? ASAP : Scheduled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})
export default ProductTileInCart;

